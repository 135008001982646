/* Navbar Main */
.nav-main {
  @include media(">desktop") {
    &__links {
      display: flex;
      justify-content: flex-end;
      gap: calc(var(--main-header-gap-between-items, 1vw) / 2);

      > .menu-item {
        position: relative;
        display: flex;
        align-items: center;

        > a,
        .plain-menu-item {
          display: block;
          white-space: nowrap;
          padding: 2.9rem 10px;
          line-height: var(--navbar-link-height);
          color: currentColor;
          transition: color var(--base-transition), font-size var(--base-transition);

          &:hover {
            color: var(--header-link-color-hover, var(--primary-color2));
          }
        }
      }
    }
  }

  .current-menu-item,
  .current-menu-ancestor {
    > a,
    > .plain-menu-item {
      color: var(--header-link-color-hover, var(--primary-color2));
    }
  }
  .nav-main__links{
    > .menu-item {
      &.current-menu-item,
      &.current-menu-ancestor {
        > a,
        > .plain-menu-item {
          border-top: 0.4rem solid var(--header-link-color-hover, var(--primary-color2));
        }
      }
    }
  }


}

/* Navbar Secondary */

.nav-secondary {
  &__links {
    @include media(">desktop") {
      display: flex;
      justify-content: flex-end;
      gap: var(--navbar-gap_h, 1vw);
    }
  }

  a {
    display: block;
    white-space: nowrap;
    color: var(--header-link-color);

    &:hover {
      text-decoration: underline;
    }
  }
}
