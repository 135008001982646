.navbar-mobile {
  --burger-width: 24px;
  --burger-segment-height: 0.2rem;
  --burger-segment-gap: 7px;
  --burger-segment-radius: 0;
  --burger-color: #fff;
  --burger-bg: var(--primary-color2);
  --header-mobile-bg: var(--primary-color2);
  --link-size-l1: 15px;
  --link-size-l2: 16px;
  --link-color: #fff;
  --link-gap: 10px;

  --toggle-submenu-btn-width: 21px;
  --toggle-submenu-btn-height: 21px;
  --toggle-submenu-btn-bdg: var(--primary-color2);
}

@include media('<=desktop') {

  .navbar-mobile {
    &__inner {
      border-top: 1px solid #1950B0;
    }

    .menu-item-img-wrapper {
      svg {
        color: var(--primary-color3);
        fill: var(--primary-color3);

        path,
        rect {
          fill: var(--primary-color3);
          stroke: rgba(255, 255, 255, 0);
        }
      }
    }

    .links-list {
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px 35px;
      border-bottom: 1px solid #1950B0;
      margin: 0;
      padding-bottom: 19px;
      padding-inline: var(--page-gutter);
      width: calc(100% + var(--page-gutter) * 2);
      margin-inline: calc(var(--page-gutter) * -1);

      &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: 400;
        text-transform: uppercase;


        &:first-of-type {
          font-size: 12px;
        }

        svg {
          color: var(--primary-color3);
          fill: var(--primary-color3);
          margin-bottom: 10px;
          width: auto;
          height: 18px;

          path,
          rect {
            fill: var(--primary-color3);
            stroke: rgba(255, 255, 255, 0);
          }
        }
      }
    }

    .c-block {
      &__btn {
        margin-bottom: 20px;

        .c-btn {
          width: 100%;

          &__txt {
            margin: auto;
            display: block;
            width: calc(100% - var(--icon-width));
          }
        }
      }
    }
    .site-header{
      &__logo{
        max-width: 65px;
        img,svg{
          width: 100%;
          height: auto;
          max-width: 100%;
          max-height: unset;
        }
      }
    }

    .social-list {
      --social-icon-bg: var(--primary-color3);
      --social-icon-color: var(--header-mobile-bg);
      --social-icon-bg-hover: var(--primary-color3);

      a {
        color: var(--social-icon-color);
      }
    }

    .nav-main {
      &__links {
        & > .menu-item {
          &.current-menu-parent, &.current-menu-item {
            & > a, .plain-menu-item {
              color: var(--primary-color3);
            }

            .sub-menu-toggle {
              border-color: var(--primary-color3);
            }
          }
        }
      }
    }
  }

  body{
    &:not(.nav-active){
      .navbar-mobile {
        --header-mobile-bg: var(--body-color);
        &__inner{
          border-top: 0;
        }
      }
      .site-header{
        &__logo{
          color: var(--primary-color2);
        }
      }
    }
  }
}
