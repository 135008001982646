.nav-main__btn {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 50px;
    color: var(--burger-color);
    background-color: var(--burger-bg);
    padding: 0;
    cursor: pointer;
    text-align: center;
    position: absolute;
    right: 15px;
    transition: all .3s;
    z-index: 10;
    &:focus{
        outline: none;
    }
}

.burger-icon {
    width: var(--burger-width);
    height: 20px;
    cursor: pointer;
    transition: box-shadow .4s ease;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
        position: relative;
        flex: none;
        width: 100%;
        height: var(--burger-segment-height);
        background: currentColor;
        transition: all 0.4s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--burger-segment-radius);

        &:before, &:after {
            content: '';
            position: absolute;
            z-index: 1;
            left: 0;
            width: 100%;
            height: var(--burger-segment-height);
            border-radius: var(--burger-segment-radius);
            background: inherit;
            transition: all .4s ease;
        }

        &:before {
            top: calc(var(--burger-segment-gap) * -1);
        }

        &:after {
            top: var(--burger-segment-gap);
        }
    }

    .is-active & {
        > span {
            transform: rotate(135deg);

            &:before,
            &:after {
                top: 0;
                transform: rotate(90deg);
                width: 100%;
            }

            &:after {
                opacity: 0;
            }
        }
    }
}
