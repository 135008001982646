/* Typography */

@import '../mixins/font-face';

// For google fonts please use https://google-webfonts-helper.herokuapp.com/fonts if possible

@include addFontFace('Campton', '../../fonts/Campton', ('woff'), (
		createFontMap('CamptonBook', 400),
		createFontMap('CamptonMedium', 500),
		createFontMap('CamptonSemiBold', 600),
		createFontMap('CamptonBold', 700),
));

/* 1rem = 10px */

html {

	font-size: 50%;

	@include media(">desktop-m") {
		font-size: 58%;
	}

	@include media(">desktop-l") {
		font-size: 62.5%;
	}
}
