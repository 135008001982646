@import 'header/header_simple';
@import 'header/header-mobile';
@import 'header/header-dropdown';
@import 'header/burger-desktop';

.site-header {
  --content-width: var(--desktop-width-l); // Container with for header wider than page containers
  --navbar-gap_v: 0;
  --navbar-gap_h: 1vw;
  --navbar-link-height: 30px;
  --header-link-color: var(--main-header-color);
  --header-link-color-hover: var(--primary-color2);
  --header-logo-width: 100%;
  --header-logo-height: 40px;
  --main-header-gap-between-items: 2vw;
}

.site-search {
  --search-size: 30px;
  --search-btn-bg: transparent;
  --search-btn-radius: 4px;
  --search-height: 30px;
  --search-width: 250px;
  --search-radius: 4px;
  --search-outer-bg: var(--primary-color2);
  --search-outer-gap: 5px;
  --search-outer-border: transparent;
  --search-btn-color: var(--primary-color2);
}

.site-header {
  &__top {
    .inner-frame {
      @include media('>desktop') {
        padding-block: 0.8rem;
      }
    }
  }

  &:not(.is-sticky) {
    &.site-header {
      &--light {
        @include media('>desktop') {
          --header-link-color-hover: var(--primary-color3);
          --header-link-color: var(--primary-text-color-alt);
          --primary-link-color: var(--primary-text-color-alt);
          --top-header-color: var(--primary-text-color-alt);

          .social-list {
            --social-icon-color: var(--primary-text-color-alt);
            --social-icon-color-hover: var(--primary-color3);
            --social-icon-bg: rgba(255, 255, 255, 0);
            --social-icon-bg-hover: rgba(255, 255, 255, 0);

            &__link {
              border: 1px solid rgba(255, 255, 255, 0.2);
            }
          }
          .c-block {
            &__btn {
              .c-btn {
                &.-primary {
                  --btn-primary-bg: var(--btn-secondary-bg);
                  --btn-primary-color: var(--btn-secondary-color);
                  --btn-primary-bg-hover: var(--btn-secondary-bg-hover);
                  --btn-primary-color-hover: var(--btn-secondary-color-hover);

                  &:hover {

                  }

                  .c-btn {
                    &__ico {
                      background-color: rgba(255, 255, 255, 0.2);

                      svg {
                        color: var(--btn-primary-color);
                      }
                    }
                  }
                }
              }
            }
          }

          .links-list {
            &__item {
              svg {
                color: var(--primary-color3);
              }

              a {
                @media (hover: hover) {
                  &:hover {
                    color: var(--primary-color3);
                  }
                }
              }
            }
          }

          .menu-item-has-children {
            > .plain-menu-item {
              &:before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath d='M9.5.5 5 5 .5.5' stroke='%23fff'/%3E%3C/svg%3E");
              }
            }
          }
        }

        .site-header {
          &__logo {
            color: var(--primary-text-color-alt);
          }
        }

        .navbar-mobile {
          @include media('<=desktop') {
            background-color: transparent;
          }
        }

        & + .site-content {
          @include media('<=desktop') {
            margin-top: calc(var(--navbar-height, 60px) * -1);
          }

          .m-banner {
            &.-version-2 {
              .m-banner {
                &__inner {
                  @include media('<=desktop') {
                    padding-top: var(--navbar-height, 60px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .links-list {
    &__item {
      a {
        @media (hover: hover) {
          &:hover {
            color: var(--primary-color2);
          }
        }
      }
    }
  }
}

.nav-active {
  .site-header:not(.is-sticky){
    &.site-header {
      &--light {
        .navbar-mobile {
          @include media('<=desktop') {
            background-color: var(--header-mobile-bg);
          }
        }
      }
    }
  }

}
