.links-list {
    display: flex;
    align-items: center;
    gap: 5rem;

    &__item {
        white-space: nowrap;
        display: flex;
        align-items: center;

        .-left & {
            &:not(:last-child) {
                margin-right: 10px;
            }
        }

        .-right & {
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }

    &__btn {
        white-space: nowrap;
        .c-btn{
            &__txt{
                padding: 0 ;
            }
        }
    }

    img, svg, picture {
        margin: 0 5px;
        max-width: 22px;
    }
}
