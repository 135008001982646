:root {
    --phone-width: 576px;
    --tablet-width: 768px;
    --desktop-width: 1112px;
    --desktop-width-sm: 1024px;
    --desktop-width-m: 1280px;
    --desktop-width-l: 1440px;
    --desktop-width-xl: 1640px;
    --desktop-width-1920: 1920px;
    --content-width: var(--desktop-width-m); // Default content width (1280)
    --blog-width: 844px;
    --page-gutter: 20px;
    --grid-gutter: 24px;
    --base-transition: .3s;		            // default transition for hovers

    --vertical-gap: 6vmin;

    @include media('<=desktop') {
        --vertical-gap: 20px;
        --page-gutter: 15px;
        --content-width: var(--tablet-width);
    }

    @include media('>desktop', '<=desktop-l') {
        --content-width: var(--desktop-width-sm);
    }

}


