// Base styles for the first viewport
// Helpers and utilities
@import '__helpers/reboot';
@import '__helpers/a11y';

// Core settings
@import '__core/core-mixins';
@import '__core/core-settings';
@import 'visuals/_scrollbars';
@import '_layout/_base';

/**** Site Header */
@import '_layout/_header';
/**** End Header */

/* Above the fold modules */
@import "_components/buttons";
//@import "_components/image";

@import "project-custom/_custom__critical";

