
.c-btn {
  &.-primary,
  &.-secondary,
  &.-inverted,
  &.-link {
    .c-btn {
      &__txt {
        line-height: 1;
      }

      &__ico {

      }
    }
  }

  &.-primary,
  &.-inverted,
  &.-secondary {
    --icon-width: 4.5rem;
    overflow: hidden;
    align-items: stretch;
    @include media('<=desktop') {
      --btn-padding-top-bottom: calc(1.4rem * 1.25);
      --btn-font-size: calc(1.2rem * 1.25);
    }

    &:hover {
      &.icon-right {
        .c-btn {
          &__txt,
          &__ico {
            transform: translateX(var(--icon-width));
          }

          &__txt {
            padding-right: var(--btn-padding-left-right);
            padding-left: calc(var(--btn-padding-left-right) - 0.6rem);
          }
        }
      }

      &.c-btn-back {
        .c-btn {
          &__txt,
          &__ico {
            transform: translateX(calc(var(--icon-width) * -1));
          }

          &__txt {
            padding-left: var(--btn-padding-left-right);
            padding-right: calc(var(--btn-padding-left-right) - 0.6rem);
          }
        }
      }
    }

    &.icon-right {
      .c-btn {
        &__txt {
          padding-right: calc(var(--btn-padding-left-right) - 0.6rem);
        }

        &__ico {
          &:not(:first-of-type) {
            margin-left: 0;
          }

          &:first-of-type {
            margin-left: calc(var(--icon-width) * -1);
          }
        }
      }
    }


    &.icon-left {
      .c-btn {
        &__txt {
          padding-left: calc(var(--btn-padding-left-right) - 0.6rem);
        }

        &__ico {
          margin-right: 0;
        }
      }
    }

    &.c-btn-back {
      .c-btn {
        &__txt {
          padding-left: calc(var(--btn-padding-left-right) - 0.6rem);
        }

        &__ico {
          svg {
            transform: rotate(-180deg);
          }

          &:not(:first-of-type) {
            margin-right: 0;
          }

          &:first-of-type {
            margin-right: calc(var(--icon-width) * -1);
          }
        }
      }
    }

    .c-btn {
      &__ico {
        width: var(--icon-width);

        svg, img {
          max-width: 2rem;
          max-height: 1.4rem;
        }
      }

      &__txt {
        @include transition;
      }

    }
  }

  &.-primary {
    .c-btn {
      &__ico {
        background-color: rgba(0, 26, 72, 0.3);

        svg {
          color: var(--primary-color3);
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      pointer-events: none;
      inset: 0;
      background: linear-gradient(74.9deg, rgba(255, 255, 255, 0) 52.23%, var(--secondary-color1) 111.43%);
      opacity: 0;
      @include transition;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  &.-inverted {
    .c-btn {
      &__ico {
        background-color: var(--primary-color2);

        svg {
          color: var(--primary-color3);
        }
      }
    }
  }

  &.-secondary {
    .c-btn {
      &__ico {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &.-link {
    --icon-width: 17px;

    &.icon-right {
      .c-btn {
        &__txt {
          margin-right: calc(var(--icon-width) + 0.5rem);
        }

        &__ico {
          margin-left: 0;
          right: 0;
        }
      }

      @media (hover: hover) {
        &:hover {
          .c-btn {
            &__ico {
              right: calc(100% - var(--icon-width));
            }

            &__txt {
              margin-right: 0;
              margin-left: calc(var(--icon-width) + 0.5rem);
            }
          }
        }
      }
    }

    &.icon-left {
      .c-btn {
        &__txt {
          margin-left: calc(var(--icon-width) + 0.5rem);
        }

        &__ico {
          margin-right: 0;
          left: 0;
        }
      }
    }

    .c-btn {
      &__txt,
      &__ico {
        @include transition;
      }

      &__txt {
        font-weight: 700;
        font-size: 14px;
        line-height: 1.2;
        color: var(--primary-color2);
        @include media('<=phone-l') {
          color: var(--primary-color1);
        }
      }

      &__ico {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--primary-color3);
        border-radius: 50%;
        min-width: var(--icon-width);
        width: var(--icon-width);
        height: var(--icon-width);

        svg, img {
          max-height: 0.555em;
          width: auto;
        }
      }

    }

    &.has-icon {

    }
  }

  &.-small {
    --btn-padding-left-right: 20px;
    --btn-padding-top-bottom: 10px;

    .c-btn__txt {
      font-size: .8em;
    }

    .icon {
      max-width: 60%;
    }
  }

  &.-large {
    .c-btn__txt {
      font-size: 1.4em;
    }
  }
}


.-colors-inverted,
.style-dark {
  .c-block {
    &__btn {
      .c-btn {
        &.-link {
          .c-btn {
            &__txt {
              color: var(--secondary-color3);
            }
          }
        }
      }
    }
  }
}


/* CF7 Submit Button */

.wpcf7-submit,
.gform_button,
.gform-button {
  @extend .c-btn, .-primary;
  font-size: var(--btn-font-size, 1.8rem);
  font-family: var(--btn-font-family);
  text-transform: var(--btn-text-transform, none);
  font-weight: var(--button-font-weight, 400);
  box-shadow: var(--btn-box-shadow, none);
  border-radius: var(--btn-border-radius, 0);
  padding: var(--btn-padding-top-bottom) var(--btn-padding-left-right);
  justify-content: center;
  transition: all var(--base-transition);

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 2px 2px rgba(0, 7, 63, 0.2);
    }
  }
}

.c-btn-panel {
  --panel-icon-width: 4rem;
  --link-icon-color: var(--secondary-color3);
  overflow: hidden;
  display: flex;
  align-items: center;

  @include media('<=desktop') {
    --panel-icon-width: calc(3rem * 1.25);
    width: 100vw;
    flex-direction: column;
  }

  &__item {
    position: relative;
    overflow: hidden;
    @include transition;

    @include media('<=desktop') {
      text-align: center;
      width: 100%;
    }
    @include media('>=desktop') {

      border-radius: 10px 0 0 10px;
      text-align: start;

      &:before {
        content: '';
        position: absolute;
        pointer-events: none;
        right: 0;
        top: 0;
        height: 100%;
        width: 0;
        background-color: var(--primary-color3);
        @include transition;
      }

      @media (hover: hover) {
        &:hover {
          &:before {
            width: 100%;
          }

          .c-btn.-link {
            .c-btn {
              &__txt {
                color: var(--primary-color1);
              }

              &__ico {
                background-color: var(--primary-color1);
                margin: 0;
              }
            }
          }

        }
      }
    }

    &:first-child {
      background-color: var(--secondary-color2);
      flex-grow: 1;
      @media (hover: hover) {
        &:hover {

        }
      }
    }

    &:nth-child(2n) {
      flex-grow: 2;
      @media (hover: hover) {
        &:hover {

        }
      }
    }

    .c-btn.-link {
      margin: 0;

      .c-btn {

        &__txt {
          font-weight: 600;
          font-size: 2.2rem;
          line-height: 1.2;
          color: var(--primary-color2);
          margin-inline: 0 1rem;
          text-transform: capitalize;
          padding-block: 3rem;
          padding-inline: 3.5rem var(--panel-icon-width);

          @include transition;

        }

        &__ico {
          width: var(--panel-icon-width);
          min-width: var(--panel-icon-width);
          height: var(--panel-icon-width);
          background-color: var(--primary-color2);
          border-radius: 50%;
          @include transition;

          svg {
            width: 2rem;
            max-height: 1.4rem;
            @include media('<=desktop') {
              width: calc(.9rem * 1.25);
            }
          }
        }
      }

      @media (hover: hover) {
        &:hover {
          .c-btn {
            &__txt {
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
}
