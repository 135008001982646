@include media('<=desktop') {
    .navbar-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px var(--page-gutter);
        height: var(--navbar-height, 60px);
        background-color: var(--header-mobile-bg);

        a {
            color: var(--link-color);
        }

        &__inner {
            top: calc(var(--navbar-height, 60px) + var(--wc-notice-height, 0px));
            background-color: var(--header-mobile-bg);
            width: 100%;
            height: 100%;
            right: 0;
            position: fixed;
            opacity: 1;
            max-height: 0;
            overflow: hidden;
            pointer-events: none;
            transition: all .3s;


            .admin-bar & {
                top: calc(var(--navbar-height, 60px) + var(--wc-notice-height, 0px) + var(--admin-bar)) !important;
            }

            .nav-active & {
                opacity: 1;
                max-height: calc(100% - calc(var(--navbar-height, 60px) + var(--wc-notice-height, 0px)));
                pointer-events: auto;
                overflow-y: auto;
                padding: 12px var(--page-gutter) 15px;
            }

            .site-header__widget {
                padding-bottom: 5px;
            }
        }

        .nav-main__links {
            a {
                display: block;
            }

            > .menu-item {
                > a, .plain-menu-item {
                    padding: calc(var(--link-gap) * 1.5) 0;
                    display: inline-block;
                    font-size: var(--link-size-l1);
                    color: var(--link-color);
                    width: 100%;
                    text-transform: uppercase;
                    border-bottom: 1px solid #1950B0;
                }
                &:last-of-type{
                    > a, .plain-menu-item {
                        border-bottom: none;
                    }
                }
            }

            .menu-item-has-children {
                padding-right: 0;

                > a,
                > .plain-menu-item {
                    position: relative;
                    padding-right: var(--toggle-submenu-btn-width);
                }

                &.is-toggled {
                    > a:after {
                        transform: rotate(0) translateY(-50%);
                    }
                }

                .sub-menu-toggle {
                    background: var(--toggle-submenu-btn-bdg);
                    width: var(--toggle-submenu-btn-width);
                    height: var(--toggle-submenu-btn-height);
                    max-height: var(--toggle-submenu-btn-height);
                }
            }
        }

        .sub-menu {
            overflow: hidden;
            max-height: 0;
            transition: all .3s ease-in-out;
            .menu-item{
                > a:not(.c-btn),
                > .plain-menu-item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 8px
                }
            }

            &.is-visible {
                max-height: 500px;
                height: 100%;
                transition: all .3s ease-in-out;
            }

            a {
                padding: var(--link-gap) 0;
                font-size: var(--link-size-l2);
                color: var(--link-color);
            }

            .current-menu-item {
                a {
                    color: var(--secondary-color1);
                }
            }
        }

        .btn-custom, .links-list {
            margin: var(--link-gap) 0;
        }
    }

    .nav-active {
        &, .site-content {
            overflow: hidden;
        }

        .site-content {
            pointer-events: none;
        }
    }
}
