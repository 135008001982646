.site-header {
    width: 100%;
    top: 0;
    left: 0;
    transition: transform var(--base-transition), background-color var(--base-transition), box-shadow var(--base-transition);
    color: var(--header-link-color);
    z-index: 99;

    @include media('<=desktop') {
        max-height: var(--navbar-height, 60px);
        position: sticky;
    }
    @include media('>desktop') {
        position: var(--header-sticky-type, sticky);
        .admin-bar & {
            top: 28px;
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        color: var(--primary-color2);
        @include media(">desktop") {
            transition: all var(--base-transition);
            max-width: 100%;
            max-height: 150px;
        }

        img, svg, picture {
            max-width: 250px;
            max-height: 100px;
            min-width: 60px;
            color: currentColor;
            width: var(--header-logo-width);
            height: var(--header-logo-height);
            object-fit: contain;
            object-position: left center;

            @include media("<=desktop") {
                max-width: 65px;
                max-height: 30px;
            }
            @include media(">desktop") {
                transition: all var(--base-transition);
            }
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
    }
    &__top{
        @include media(">desktop") {
            border-bottom: 1px solid var(--border-color);
        }
        .site-header{
            &__col{
                @include media(">desktop") {
                    padding-top: 3px;
                }
            }
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        transition: all var(--base-transition);
        padding-block: var(--navbar-gap_v);

        @include media("<=desktop") {
            flex-direction: column;
        }
    }

    &__col {
        display: flex;
        align-items: center;
        gap: var(--main-header-gap-between-items, 1vw);
        transition: padding var(--base-transition);

        a.right,
        a.left {
            img, picture {
                max-height: 30px;
            }
        }

        @include media("<=desktop") {
            flex-direction: column;
            width: 100%;
        }
        @include media(">desktop") {
            &.-left {
                justify-content: flex-start;
            }
            &.-center {
                justify-content: center;
                text-align: center;
            }
            &.-right {
                justify-content: flex-end;
            }
        }
    }

    &__widget {
        @include media("<=desktop") {
            width: 100%;
        }
        .c-block__btn {
            display: flex;
            align-items: center;
            gap: var(--main-header-gap-between-items);
        }
    }

    .social-list {
        gap: 1rem;
        @include media('<=desktop'){
            gap: calc(1.4rem * 1.25);
        }
    }

    &__separator {
        height: 1em;
        width: 1px;
        background: currentColor;
    }

}
