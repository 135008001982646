.site-header {
  --dropdown-bg: #fff;
  --dropdown-color: var(--primary-color1);
  --dropdown-color-active: var(--primary-color2);
  --dropdown-font-size: 16px;
  --dropdown-font-weight: 600;
  --dropdown-padding: 0;
  --dropdown-width: 29.7rem;
  --dropdown-offset: 1px;

  --toggle-submenu-btn-width: 21px;
}

.nav-main {
  .menu-item {
    &:not(.mega-menu) {
      .sub-menu {
        @include media('>desktop') {
          .menu-item {
            &:first-of-type {
              border-top-right-radius: 1.5rem;
            }

            &:last-of-type {
              border-bottom-right-radius: 1.5rem;
            }
          }
        }
      }
    }

    &.childs-two-columns {
      @include media('>desktop') {
        --dropdown-width: 330px;

        &:hover {
          .sub-menu {
            transform: translate(-25%, 0);
          }
        }
      }


      .sub-menu {
        --column-gap: 0;
        --custom-border: 0 solid var(--primary-color3);
        --custom-border-clear: 2px solid transparent;

        @include media('>desktop') {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          column-gap: var(--column-gap);
          max-height: 45rem;
          width: calc(var(--dropdown-width) * 2);
          background-color: transparent;
          box-shadow: none;
          transform: translate(-25%, 0);
        }

        &:before {
          @include media('>desktop') {
            left: 25%;
          }
        }

        .menu-item {
          @include media('>desktop') {
            width: calc(50% - var(--column-gap) / 2);
            background-color: var(--secondary-color3);
            border-right: var(--custom-border-clear);
            border-left: var(--custom-border);
            padding-block: .4rem;

            &:hover {
              background-color: var(--primary-color2);
            }
          }

          &:first-child {
            @include media('>desktop') {
              border-top-right-radius: 0;
              border-top: var(--custom-border);
            }
          }

          &:last-child {
            @include media('>desktop') {
              border-bottom-right-radius: 1.5rem;
              border-bottom-left-radius: 0;
              border-bottom: var(--custom-border);
            }
          }

          &:nth-child(7n) {
            @include media('>desktop') {
              border-bottom: var(--custom-border);
              & + .menu-item {
                border-top-right-radius: 1.5rem;
                border-top: var(--custom-border);
              }
              & ~ .menu-item {
                border-left: 2px solid #E7F5FF;
              }
            }
          }

          &.rounded-border {
            &__br {
              border-bottom-right-radius: 1.5rem;
            }

            &__bl {
              border-bottom-left-radius: 1.5rem;
            }

            &__tr {
              border-top-right-radius: 1.5rem;
            }

            &__tl {
              border-top-left-radius: 1.5rem;
            }
          }
        }
      }
    }
  }

  .mega-menu {
    .sub-menu {
      @include media('>desktop') {
        &:hover {
          border-radius: 1.5rem 0 0 1.5rem;
        }
      }
    }
  }

  .sub-menu {
    text-align: left;

    @include media('>desktop') {
      border-radius: 1.5rem;
      position: absolute;
      opacity: 0;
      pointer-events: none;
      top: calc(100% + var(--dropdown-offset));
      transform: translate(-50%, -10px);
      left: 50%;
      padding: var(--dropdown-padding);
      min-width: var(--dropdown-width);
      width: var(--dropdown-width);
      z-index: 10;
      background-color: var(--dropdown-bg);
      box-shadow: 0 4px 34px rgba(0, 7, 63, 0.1);
      transition: opacity .3s, transform .3s;

      &:hover {
        & .menu-item {
          .info-box.info-box {
            pointer-events: auto;
          }
        }

      }
      a:not(.c-btn) {
        color: var(--dropdown-color);
        display: flex;
        width: 100%;
        align-items: center;
        font-weight: var(--dropdown-font-weight);
        font-size: var(--dropdown-font-size);
        @include transition;

        &:hover {
          color: var(--dropdown-color-active);
        }
      }

      .current-menu-item a:not(.c-btn) {
        color: var(--dropdown-color-active)
      }

      .menu-item-content {
        min-width: 150px;
        width: 100%;
      }

      .menu-item-title {
        display: block;
        margin-bottom: 5px;
      }

      .menu-item-caption {
        font-weight: 400;
        color: var(--primary-color1);
      }

      .menu-item-img-wrapper {
        margin-right: 10px;
        min-width: 30px;
        max-width: 150px;

        img, svg {
          max-width: 100%;
          height: auto;
        }
      }

      .menu-item {
        @include transition;

        &:not(:last-of-type) {
          border-bottom: 1px solid #E5E5E5;
        }

        a:not(.c-btn) {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            right: 6.4rem;
            transform: translate(0, -50%) rotate(315deg);
            border-bottom: 3px solid var(--primary-color3);
            border-right: 3px solid var(--primary-color3);
            width: 10px;
            height: 10px;
            transition: transform 0.36s ease-in-out;
            @include transition;
            opacity: 0;
          }
        }

        &:hover {
          background-color: var(--primary-color2);

          a:not(.c-btn) {
            color: var(--secondary-color3);

            &:before {
              opacity: 1;
              right: 2.4rem;
            }
          }

          .menu-item-img-wrapper {
            svg {
              color: var(--secondary-color3);
              fill: var(--secondary-color3);

              path,
              rect {
                fill: var(--secondary-color3);
              }
            }
          }


          .info-box {
            opacity: 1;
            z-index: 2;
          }
        }

        .info-box {
          border-radius: 0 1.5rem 1.5rem 0;
          background-color: var(--dropdown-bg);
          position: absolute;
          left: 100%;
          top: 0;
          width: 64.6rem;
          max-width: unset;
          min-height: 100%;
          padding: 3.8rem 5.9rem 5.7rem 4rem;
          opacity: 0;
          z-index: -1;
          transition: left .3s ease, opacity .3s ease;
          pointer-events: none;
          color: var(--primary-text-color);
          border-left: 1px solid #E5E5E5;

          &__pre-title {
            color: var(--primary-color2);
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.332rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
          }

          &__title {
            color: #0B111A;
            font-size: 2.8rem;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 2.3rem;
          }

          &__description {
            font-size: 1.5rem;
            font-weight: 400;
            opacity: 0.899999;
            line-height: 1.4;
            margin-bottom: 1.6rem;

            ul {
              list-style: initial;
              column-count: 2;
              column-gap: 4rem;

              li, ol {
                margin-bottom: 0.8rem;
                padding-left: 2rem;
                display: inline-block;
                min-width: 100%;
              }

              li {
                position: relative;

                &:before {
                  position: absolute;
                  content: '';
                  top: 0.5em;
                  left: 0;
                  width: 0.7rem;
                  height: 0.7rem;
                  border-radius: 0.7rem;
                  background-color: var(--primary-color2);
                }
              }
            }
          }
        }

        a:not(.c-btn) {
          padding: 1.6rem 4rem 1.6rem 2.5rem;
          transition: all .3s;
        }
      }

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent var(--dropdown-bg) transparent;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
      }

      &:after {
        content: '';
        position: absolute;
        height: var(--dropdown-offset);
        width: 100%;
        left: 0;
        bottom: calc(100%);
        z-index: -1;
      }

      .sub-menu {
        top: 0;
        left: 100%;
        transform: none !important;

        &::before {
          border-width: 10px 10px 10px 0;
          border-color: transparent var(--dropdown-bg) transparent transparent;
          right: 100%;
          top: unset;
          left: unset;
          transform: none;
        }
      }
    }

    @include media('<=desktop') {
      .menu-item-caption {
        display: none;
      }
    }
  }

  .info-box {
    @include media('<=desktop') {
      display: none;
    }
  }

  /* Sub menu toggle */
  .menu-item-has-children {
    position: relative;

    > a:not(.c-btn),
    > .plain-menu-item {
      padding-right: calc(var(--toggle-submenu-btn-width) + 5px);
    }

    &:hover {
      @include media('>desktop') {
        > .sub-menu-toggle {
          border-color: var(--header-link-color-hover, var(--primary-color2));
          background-color: var(--header-link-color-hover, var(--primary-color2));

          &:before {
            border-color: var(--secondary-color3);
          }
        }
        > .plain-menu-item {
          &:before {
            border-color: var(--header-link-color-hover, var(--primary-color2));
            background-color: var(--header-link-color-hover, var(--primary-color2));
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath d='M9.5.5 5 5 .5.5' stroke='%23fff'/%3E%3C/svg%3E");
          }
        }
        > .sub-menu {
          opacity: 1;
          pointer-events: auto;
          transform: translate(-50%, 0);
        }
      }
    }

    .sub-menu-toggle,
    .plain-menu-item {
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%) rotate(135deg);
        border-top: 1px solid var(--link-color);
        border-right: 1px solid var(--link-color);
        width: 7px;
        height: 7px;
        transition: transform 0.36s ease-in-out;
        @include media('>desktop') {
          border-top: 1px solid currentColor;
          border-right: 1px solid currentColor;
        }
      }

      &.is-toggled {
        &:before {
          @include media('>desktop') {
            transform: translate(-50%, -50%) rotate(315deg);
          }
        }
      }
    }

    .sub-menu-toggle {
      position: absolute;
      top: 25px;
      right: 0;
      transform: translateY(-50%);
      width: var(--toggle-submenu-btn-width);
      height: var(--toggle-submenu-btn-width);
      border-radius: var(--toggle-submenu-btn-width);
      border: 1px solid currentColor;
      @include transition;
      @include media('>desktop') {
        top: 50%;
      }
      @include media('<=desktop') {
        border-color: var(--secondary-color3)
      }
    }

    .plain-menu-item {
      position: relative;

      &:before {
        right: 0;
        left: unset;
        width: var(--toggle-submenu-btn-width);
        height: var(--toggle-submenu-btn-width);
        border-radius: var(--toggle-submenu-btn-width);
        border: 1px solid currentColor;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath d='M9.5.5 5 5 .5.5' stroke='%23001A48'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        transform: translate(0, -50%);
        @include transition;
        @include media('<=desktop') {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none'%3E%3Cpath d='M9.5.5 5 5 .5.5' stroke='%23fff'/%3E%3C/svg%3E");
        }
      }
    }

    .menu-item {
      .menu-item-img-wrapper {
        width: 30px;
        @include media('<=desktop') {
          width: 31px;
        }
      }
    }

    .sub-menu {
      text-transform: initial;

      .menu-item {
        font-weight: 600;
        line-height: 1.1;

        &:first-of-type {
          border-top-left-radius: 1.5rem;
        }

        &:last-of-type {
          border-bottom-left-radius: 1.5rem;
        }
      }
    }
  }

  /* End sub menu toggle */
}
